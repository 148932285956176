@import 'reset.scss';
@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Epilogue', sans-serif;
  background-color: #000000;
  color: white;
  width: 100%;
  height: 100%;
}

/* ALL TEXT AND HEADINGS */

a {
  all: unset;
  cursor: pointer;
}
p {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.8;
  max-width: 40rem;
}
h1,
h2 {
  font-family: 'Lato', sans-serif;
}
h1 {
  line-height: 1;
  font-weight: 400;
  font-size: 50px;
  margin-bottom: 15px;
  max-width: 40rem;
}

h2 {
  line-height: 1;
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 15px;
  max-width: 40rem;
}

.ingress {
  font-size: 12px;
  margin: 0px;
  color: #ffe26a;
}

iframe {
  width: 100%;
  padding: 0px;
  margin: 0;
}


/* SWTICH FROM DARK TO LIGHT */

.switch {
  background-color: #ed0b70;
  position: fixed;
  bottom: 20px;
  right: 40px;
  border-radius: 2px;
  filter: drop-shadow(0px 3px 6px #00000045);
  z-index: 1000;
}

.emneListe {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 12px;
  background-color: #ed0b70;
  padding: 10px;
  font-style: italic;
}

#light .emneListe {
  background-color: white;
}


/* EVERYTHING THAT IS USED ON LIGHT THEME*/
#light body {
  background-color: #f6f6f6;
}
#light {
  background-color: #f6f6f6;
  color: #111111;
}
#light h1 {
  color: #000000;
}
#light .ingress {
  color: #ed0b70;
}
#light .switch {
  filter: drop-shadow(0px 3px 6px #00000005);
}
#light .logo {
  color: #111111;
}
#light .logo:hover {
  color: #ed0b70;
}
#light aside {
  background-color: #ffffff;
  filter: drop-shadow(0px 3px 6px #0000000a);
}

#light .fa-brands {
  color: #3a3a3a;
}

#light .fa-solid {
  color: #3a3a3a;
}

#light header {
  background-color: #ffffff;
}

#light nav ul li {
  color: #111111;
  font-weight: 300;
}

#light nav ul li:hover {
  color: #ed0b70;
  transition: all 0.2s;
}

#light button {
  background: rgb(252, 252, 252);
  color: #16123f;
  padding-bottom: 8px;
  background-color: #f6f6f6;
  filter: drop-shadow(0px 0px 3px #00000015);
}

#light button:hover {
  color: #ed0b70;
}

.logo {
  font-size: 34px;
  margin-left: 40px;
  font-family: 'Lato', sans-serif;
  font-weight: 800;
  color: #ffe26a;
}
.logo:hover {
  color: #ed0b70;
  transition: all 0.2s;
}

/* ALL THE CSS FOR THE ASIDE SECTION/SOCIAL MEDIA*/
aside {
  background-color: #111111;
  position: fixed;
  bottom: 0%;
  left: 40px;
  padding: 10px 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  filter: drop-shadow(0px 3px 6px #00000045);
}

aside ul {
  list-style-type: none;
  overflow: visible;
}
aside ul li {
  padding: 15px 0px;
}

.fa-solid {
  color: #fcfcfc;
}

.fa-brands {
  color: #fcfcfc;
}

.fa-solid:hover {
  transition: all 0.2s;
  color: #ed0b70;
  transform: rotate3d(1, 1, 1, 45deg);
}
.fa-brands:hover {
  transition: all 0.2s;
  color: #ed0b70;
  transform: rotate3d(1, 1, 1, 45deg);
}
/* ALL THE CSS FOR THE HEADER AND NAV*/

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  height: 12vh;
  padding-left: 0px;
  background-color: #111111;
  transition: transform 150ms ease-in-out;
  z-index: 100;
}

header.header-bar--hidden {
  transform: translateY(-100%);
}

nav {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  color: #ffe26a;
  margin-right: 40px;
  ul {
    display: flex;
    list-style-type: none;
    flex-direction: row;
  }
  li {
    padding-left: 40px;
  }
  :hover {
    color: #ed0b70;
    transition: all 0.2s;
  }
}

.hamburger {
  display: none;
}
/* ALL THE CSS FOR THE MAIN SECTION*/

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home {
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 100%;
}
/* ALL THE CSS FOR THE FOOTER SECTION*/
footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 1px solid #f6f6f625;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 15px;
  height: 80px;
}

.footer2 {
  padding-top: 26px;
}

/* ALL THE CSS FOR THE FOOTER SECTION*/

button {
  background: #f6f6f6;

  border-radius: 2px;
  padding: 7px 7px;
  color: #111111;
  font-weight: 400;
  border: none;
  font-size: 0.8rem;
  width: 98px;
  margin-top: 20px;
  filter: drop-shadow(0px 0px 6px #00000045);
  cursor: pointer;
}

button:hover {
  transition: all 0.2s;
  color: #ed0b70;
  scale: 1.04;
  filter: drop-shadow(0px 0px 0px #00000045);
}

/* GRID TEMPLATE ROW HOME*/

.gridHome {
  display: grid;
  grid-template-columns: 50vw 49vw;
  grid-template-rows: 44vh 44vh;
}
/* HER KOMMER FOR UTVALGT PORTFOLIO SIDEN, EGEN CSS */

.portfolios {
  display: flex;
  flex-direction: column;

  width: 100%;
}
.portfolios article {
  display: flex;
  flex-direction: row;
  min-height: 100%;
}

.portfolios article h1 {
  font-size: 2.3rem;
  margin: 5px 0px;
}

.portfolios article p {
  font-size: 14px;
  max-width: 16rem;
}
.portfolios article:nth-child(even) {
  flex-direction: row-reverse;
}

.informasjon {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  background-color: #1a1a1a;
  padding: 2vw 2vh;
  padding-left: 8%;
}

.bilde {
  width: 50%;

  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
}

.portfolios article ul {
  background-color: #2d2d2d;
  padding: 5px 10px;
  display: none;
  flex-direction: row;
  align-items: center;
  column-gap: 18px;
  color: #ffe26a;
  font-size: 10px;
  max-width: 90%;
  border-radius: 2px;
  flex-wrap: wrap;
  line-height: 25px;
}

.portfolios article ul li {
  display: flex;
}
.buttons {
  display: flex;
  justify-content: space-between;
}

.lineUp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
}

/* ALL LIGHT MODE FOR THE PORTFOLIO */
#light .informasjon {
  background-color: #f1f1f1;
}

#light .portfolios article ul {
  background-color: #ffffff;
  color: #ed0b70;
}

/* HERE IS THE CSS FOR THE PORTFOLIO MAIN SITE */
.mouseClassList {
  position: fixed;
  bottom: 4%;
}
.portfoliosMain {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  flex: 50%;
  padding-right: 0px;
  padding-left: 0px;
  row-gap: 60px;
  margin-top: 70px;
  margin-bottom: 80px;
}
.portfoliosMain article {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: center;
}
.bgPortfolio {
  width: 90%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.bgPortfolio:hover + .informasjonMain {
  display: flex;
}

.informasjonMain {
  display: flex;
  height: 100%;
  flex-direction: column;
  row-gap: 10px;
  padding-left: 10%;
  padding-right: 10%;
  justify-content: center;
  align-items: center;
  background-color: #1a1a1aa4;
  width: 100%;
}
.informasjonMainMobile {
  display: none;
  height: 100%;
  flex-direction: column;
  row-gap: 10px;
  padding-left: 10%;
  padding-right: 10%;
  justify-content: center;
  align-items: flex-start;
  background-color: #1a1a1aa4;
  width: 100%;
  align-content: flex-start;
}

.informasjonMainMobile ul li{
  text-align: left;
}



.bildeMain {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2px;
  display: flex;
  align-items: flex-end;
}

.portfoliosMain article ul {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 20px;
  color: #ed0b70;
}


.portfolios article ul li {
  display: inline;
}

/* ALL LIGHT MODE FOR THE PORTFOLIO MAIN SITE */

#light article ul {
  background-color: #fff1b7;
}

#light .portfoliosMain article ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  background-color: none;
  color: #ed0b70;
  background-color: #ffffff00;
}

#light .informasjonMain {
  background-color: rgba(255, 255, 255, 0.753);
}

#light .informasjonMainMobile {
  background-color: rgba(255, 255, 255, 0.753);
}

/* HER KOMMER KONTAKT SIDEN - EGEN CSS */
main .kontakt {
  height: 90vh;
  width: 100vw;
  margin-top: 10vh;
  margin-left: 40px;
  align-self: flex-start;
}
/* HER KOMMER OM MEG SIDEN - EGEN CSS */
main .ommeg {
  height: auto;
  align-self: flex-start;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  background-image: url("img/ark3.png"); /* The image used */
  background-position: right; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;
   /* Resize the background image to cover the entire container */
}

.buttonOmmeg {
  height: 200px;
  display: flex;
  justify-content: flex-start;
}
.ommeg a {
  color: #ffe26a;
  transition: all 0.2s;
} 
.ommeg a:hover {
  color: #f1efe5;

}
.ommeg p {
  max-width: 60rem;
  background-color: rgba(0, 0, 0, 0.509);
  padding: 0;
  border-radius: 2px;
}

#light .ommeg p {
  background-color: #f6f6f6ac;
}

#light main .ommeg {
  height: 88vh;
  align-self: flex-start;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  background-image: url("img/ark4.png"); /* The image used */
  background-position: right; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;
   /* Resize the background image to cover the entire container */
}

.mailto {
  text-decoration: underline;
  margin-left: 5px;
}

.mailto:hover {
  color: #ed0b70;
}

.kontaktinfo {
  height: 78vh;
}

/* ALL THE CSS FOR THE CASE STUDY SITE */
.bildecs {
  width: 100%;
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 700px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  margin-top: 200px;
}

.casestudy {
  margin-top: 40px;
  margin-left: 20%;
}

.casestudy2 {
  margin-top: 100px;
  margin-right: 20%;
  margin-bottom: 200px;
}

.casestudy ul {
  margin-bottom: 20px;
  margin-left: 20px;
  list-style-type: disc;
}
.casestudy li {
  margin-bottom: 5px;
}

.casestudy2 div{
padding-right: 50px;
 margin-bottom: 50px;
 padding-left: 50px;
 .youTube {
  padding-right: 0px;
 margin-bottom: 50px;
 margin-top: 50px;
 padding-left: 0px;
 }
}
.casestudy2 a {
  color: #ffe26a;
  transition: all 0.2s;
}

#light .casestudy2 a {
  color: #ed0b70;
  transition: all 0.2s;
}

#light .casestudy2 ul {
  background-color: rgba(0, 0, 0, 0);
}
.casestudy2 h2 {
  margin-top: 40px;
}
.casestudy2 a:hover {
  color: #eaeaea;
}


.casestudy2,  img {
  width: 100%;
}
.casestudy2 p {
  max-width: 100%;
  margin-bottom: 5px;
}
.containerArticleCasestudy {
  display: flex;
  flex-direction: row;
  column-gap: 20%;
  justify-content: space-between;
}
.rightAlign {
padding-right: 40px;
}
.ingress2 {
  font-weight: 600;
  
}

.tilbake {
  display: flex;
  flex-direction: row;
}
.tilbake p {
  font-size: 14px;
  margin-bottom: 150px;
  color: #ffe26a;
}

.tilbake p:hover {
  color: #ed0b70;
  transition: all 0.2s;
}

.fa-arrow-left-long {
  margin-right: 10px;
}
/* ALL THE CSS FOR THE CASE STUDY LIGHT SITE */

#light .tilbake p {
  color: #16123f;
}
#light .tilbake p:hover {
  color: #ed0b70;
}

/* Loading CSS*/

.loading {
  background-color: white;
  width: 100vh;
  height: 100vh;
  display: flex;
  position: absolute;
  z-index: 50;
}

/*SCROLL BAR*/
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f6f6f6;
}
#light::-webkit-scrollbar-track {
  background: #000000;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
}
#light::-webkit-scrollbar-thumb {
  background: f6f6f6;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*MEDIA QUERIES*/ /*MEDIA QUERIES*/ /*MEDIA QUERIES*/
@media only screen and (max-width: 1250px) {
  .informasjon {
    padding: 2vw 2vh;
    
  }

  .portfolios article h2 {
    font-size: 2rem;
    margin: 5px 0px;
  }

}
@media only screen and (max-width: 1050px) {
  main .ommeg {
    height: 100%;
    align-self: flex-end;

    background-position: bottom right; /* Center thimage */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain;
     /* Resize the background image to cover the entire container */
  }

  #light   main .ommeg {
    height: 100%;
    align-self: flex-end;

    background-position: bottom right; /* Center thimage */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain;
     /* Resize the background image to cover the entire container */
  }
  .informasjonMainMobile {
    display: flex;
    align-items: flex-start;
    text-align: left;
  }
  .informasjonMain {
    display: none;
  }

  .gridHome {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    min-width: 98vw;
  }
  .portfolios article {
    display: flex;
    flex-direction: row;
    min-height: 50vh;
    height: 50%;
  }
  main {
    align-content: stretch;
  }

  .home {
    width: 100%;
  }
  .informasjon {
    padding-left: 5%;
  }
  /*HAMBURGER MENY*/
  .hamburger {
    position: absolute;
    right: 40px;
    cursor: pointer;
    display: block;
    color: white;
  }
  #light .hamburger {
    color: #000000;
  }
  .navigasjon-mobile ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    row-gap: 20px;
    top: 12vh;
    right: 0;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    width: 100%;
    background-color: #1a1a1a;
  }

  #light .navigasjon-mobile ul {
    background-color: #e7e7e7;
  }

  .navigasjon {
    display: none;
  }

  aside {
    bottom: 0%;
    left: 35%;
    border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    width: 30%;
  }

  .social {
    display: flex;
    column-gap: 25px;
    width: 100%;
    justify-content: center;
  }
  .portfoliosMain {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    row-gap: 60px;
    margin-top: 70px;
    article {
      padding-right: 0px;
      padding-left: 0px;
      width: 100%;
    }
  }
  .informasjonMain {
    margin: 0;
    padding: 0;
    background: rgba(0, 0, 0, 0.6);
    align-items: center;
  }
}

/* CSS FOR THE LOADING CONTAINERS */

.container,
.container2 {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .containerArticleCasestudy { 
    flex-direction: column;
  }
  .casestudy {
    margin-top: 40px;
    margin-left: 10%;
  }
  .casestudy2 {
    margin-top: 100px;
    margin-right: 5%;
    margin-bottom: 200px;
  }
  
.casestudy2 div{
  padding-right: 10px;
  
   margin-bottom: 50px;
   padding-left: 0px;
  }
  aside {
    bottom: 0%;
    left: 0%;
    border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    width: 100%;
  }

  p {
    max-width: 20rem;
  }

  .social {
    display: flex;
    column-gap: 25px;
    width: 100%;
    padding-left: 35px;
    justify-content: flex-start;
  }

  .portfolios {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 20px;
    margin-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .portfolios article {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 35vh;
    height: 50%;
  }

  .portfolios article:nth-child(even) {
    flex-direction: row-reverse;
    min-height: 35vh;
    height: 50%;
  }
  .portfolios article:nth-child(even) .bilde {
    width: 0;
  }

  .portfolios article h1 {
    font-size: 1.5rem;
    margin: 5px 0px;
  }

  .informasjon {
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .bilde {
    height: auto;
    width: 0;
  }
  footer {
    margin-bottom: 68px;
  }
  h1 {
    font-size: 30px;
  }
  .logo {
    font-size: 34px;
    margin-left: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  nav {
    margin-right: 30px;
  }
  .logo:hover {
    color: #ffe26a;
    transition: all 0.2s;
  }
  .informasjonMain {
    margin: 0;
    padding: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  .portfoliosMain article ul {
    flex-direction: column;
    gap: 10px;
  }
  #light .portfoliosMain article ul {
    flex-direction: column;
    gap: 10px;
  }
  .portfoliosMain article {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 400px) {
  .hamburger {
    right: 40px;
  }
  .portfoliosMain article {
    padding-left: 20px;
    padding-right: 20px;
  }
}
